import {Head} from "@inertiajs/react";

export default function ErrorPage({status, message = null}) {
    const title = {
        503: "Service Unavailable",
        500: "Whoops! We ran into a snag.",
        404: "Page Not Found",
        403: "Forbidden",
    }[status];

    const description = {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Please contact us if the problem persists.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page.",
    }[status];

    return (
        <div className="min-h-screen bg-indigo-800">
            <div className="flex justify-center p-5 text-white">
                <Head title={title}/>
                <div className="border border-indigo-100 border-1 p-6 max-w-xl mx-auto mt-24">
                    <img
                        className="h-8 w-auto mb-8"
                        src="/storage/images/logo_light.svg"
                        alt="Conductly"
                    />
                    <h1 className="text-3xl text-white font-medium">{title}</h1>
                    <div className="mt-3 text-lg leading-tight">{message}</div>
                    <div className="mt-3 text-lg leading-tight">{description}</div>
                </div>
            </div>
        </div>
    );
}
